@import "vars";
@import "keyframes";

*{
    font-family: rubik, sans-serif;
    box-sizing: border-box;
    color: $dark-blue;
    text-align: center;
}

p{
    padding: 0px 25px;
    line-height: 1.4;
    font-size: 1.1em;
    max-width: 600px;
    margin: 20px auto;
    @media screen and (min-width: 600px){
        font-size: 1.2rem;
    }
}

.portrait{
    margin-top: 20px;
    max-width: 300px;
    width: 70%;
    margin-bottom: 20px;
}

// .masthead{
//     animation: bounce-in 2s ease-out;
// }

.masthead img{
    margin: 15px auto;
    width: 100%;
    max-width: 600px;
}

// h1{
//     margin-top: 0px;
//     text-transform: uppercase;
//     font-weight: black;
//     font-size: 3.5rem;
//     line-height: 1;
//     background: repeating-linear-gradient(135deg, #F861B8 0px, #F861B8 60px, #E5338B 60px, #E5338B 120px, #50549D 120px, #50549D 180px, #FF7255 180px, #FF7255 240px, #FF9D47 240px,#FF9D47 300px);
//     background-size: 424px 424px;
//     -webkit-text-fill-color: transparent;
//     -webkit-background-clip: text;
//     background-clip: text;
//     // animation: scroll 20s infinite;
//     // animation-timing-function: linear;
//     margin-bottom: 0px;
//     @media screen and (min-width: 600px){
//         font-size: 6.5rem;
//     }
//     @media screen and (min-width: 900px){
//         font-size: 8.5rem;
//     }
// }

h2{
    margin-top: 10px;
    font-weight: normal;
    font-size: 1.7rem;
    @media screen and (min-width: 600px){
        font-size: 2.5rem;
    }
}

.contact{
    margin: 40px 0px;
    @media screen and (min-width: 600px){
        margin: 60px 0px;
    }
    p{
        margin: 0;
        margin-bottom: 5px;
        max-width: 100%;
        font-size: 1.2rem;
        @media screen and (min-width: 600px){
            font-size: 1.8rem;
        }
    }
}

a{
    text-decoration: none;
    position: relative;
    &:before{
        content: "";
        display: inline-block;
        position: absolute;
        bottom: 1px;
        left: 0px;
        width: 100%;
        z-index: -1;
        border-bottom: 6px solid $light-orange;
        transition: 0.1s ease-out;
    }
    &.pink{
        &:before{
            border-color: $light-pink;
        }
    }
    &:hover, &:focus{
        &:before{
            border-width: 23px;
            opacity: 0.3;
        }
    }
}

.polaroids{
    padding: 20px 10px;
    list-style: none;
    max-width: 300px;
    margin: 0 auto;
    @supports(display: grid){
        @media screen and (min-width: 900px){
            max-width: 900px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 20px;
        }
    }
}

.polaroid{
    background: white;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    margin: 0px 0px 10px 0px;
    padding: 5% 5% 20% 5%;
    border-radius: 2px;

    &:nth-of-type(odd){
        transform: rotate(2deg) scale(1.02);
    }
    &:nth-of-type(even){
        transform: rotate(-5deg) scale(1.02);
    }

    @media screen and (min-width: 900px){
        transition: 0.1s ease-out;
        &:hover{
            &:nth-of-type(odd){
                transform: rotate(2deg) scale(1.1);
            }
            &:nth-of-type(even){
                transform: rotate(-5deg) scale(1.1);
            }
            .polaroid__inner{
                img{
                    opacity: 0.5;
                    filter: brightness(1) contrast(1);
                }
            }
        }
    }

}

.polaroid__inner{
    background: $purple;
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

    img{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        filter: brightness(0.8) contrast(1.2);
        transition: 0.1s ease-out;
    }

    h2{
        color: white;
        margin: 0;
        text-align: left;
        position: absolute;
        bottom: 5%;
        left: 5%;
        text-shadow: 0px 0px 10px $purple;
        @media screen and (min-width: 600px){
            font-size: 2rem;
        }
    }
}


.call-to-action{
    border: 6px solid $pink;
    box-shadow: 0px 0px 0px 4px solid $pink;
    border-radius: 100%;
    width: 250px;
    height: 250px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
    // animation: neon 3s infinite;
    // animation-delay: 2s;
    h2, p{
        margin: 0;
        width: 100%;
    }
    h2{
        margin-bottom: 10px;
    }
    &:after{
        content: "";
        border-radius: 100%;
        position: absolute;
        top: 4px;
        right: 4px;
        left: 4px;
        bottom: 4px;
        border: 6px solid $purple;
        z-index: -1;
        // animation: neon 3s infinite;
    }
    &:before{
        content: "";
        border-radius: 100%;
        position: absolute;
        top: -16px;
        right: -16px;
        left: -16px;
        bottom: -16px;
        border: 6px solid $orange;
        z-index: -1;
        // animation: neon 3s infinite;
        // animation-delay: 4s;
    }
    @media screen and (min-width: 600px){
        width: 400px;
        height: 400px;
        padding: 40px;
        margin: 40px auto;
        p{
            font-size: 1.4rem;
        }
    }
}
